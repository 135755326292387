<template>
  <div :style="style" :class="className">
    <!-- mobile -->
    <template v-if="mq['sm-']">
      <div v-if="display.map.mobile" class="v-collection-index__map-wrapper">
        <Map
          :markers="markers"
          :current-marker-id="focusedDocId"
          :offset="mobileOffset"
          :zoom-correction="zoomCorrection"
          :overlays="overlays"
        />
      </div>

      <div class="v-collection-index__router-view-wrapper">
        <router-view />
      </div>

      <template v-if="!hidden.collection.mobile">
        <HeightObserver
          v-if="collectionCarouselReady && !hidden.map.mobile"
          class="v-collection-index__carousel-wrapper"
          @notify="updateMobileOffset"
        >
          <CollectionCarousel
            :collection="collection"
            :type="type"
            @focus-change="onFocusChange"
          />
        </HeightObserver>

        <CollectionPreview
          v-if="collectionPreviewReady && hidden.map.mobile"
          :current-doc-id="currentDocId"
          :collection="collection"
          :type="type"
          header-classes="pv-2 ph-3"
          body-classes="p-3"
          @focus-change="onFocusChange"
        />
      </template>
    </template>

    <!-- desktop -->
    <template v-else>
      <div class="v-collection-index__preview-wrapper">
        <CollectionPreview
          v-if="!hidden.collection.desktop && collectionPreviewReady"
          :current-doc-id="currentDocId"
          :collection="collection"
          :type="type"
          :hide-header="type === 'projects'"
          header-classes="pv-2 ph-3"
          body-classes="p-3"
          @focus-change="onFocusChange"
        />
      </div>

      <div class="d-flex-r">
        <div v-if="display.map.desktop" class="v-collection-index__map-wrapper">
          <Map
            :markers="markers"
            :current-marker-id="focusedDocId"
            :offset="desktopOffset"
            :zoom-correction="zoomCorrection"
            :overlays="overlays"
            :controls="controls"
          />
        </div>

        <div class="v-collection-index__router-view-wrapper">
          <router-view />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import i18n from '@/i18n';
import { WithProject, WithViewport } from '@/mixins';
import { findRouteWithMeta } from '@/helpers';
import { isValidPosition } from '@/components/the-big-ones/Map/helpers';

// components
import CollectionCarousel from '@/components/collection/CollectionCarousel.vue';
import CollectionPreview from '@/components/collection/CollectionPreview.vue';
import HeightObserver from '@/components/utils/HeightObserver.vue';
import Map from '@/components/the-big-ones/Map/index.vue';
import ProjectForecast from '@/components/collection/projects/ProjectForecast/index.vue';

export default {
  name: 'VCollectionIndex',
  components: { CollectionCarousel, CollectionPreview, HeightObserver, Map },
  mixins: [WithProject(), WithViewport],
  data() {
    return {
      focusedDocId: '',

      mobileOffset: { top: 0, right: 0, bottom: 0, left: 0 },
      desktopOffset: { top: 0, right: 0, bottom: 0, left: 0 },
    };
  },
  computed: {
    ...mapState('ui', {
      collectionIds: state => state.collectionIndex.collectionIds,
      type: state => state.collectionIndex.type,
    }),
    ...mapGetters('collections', ['getProjectsByIds', 'getServicesByIds']),

    collection() {
      const { collectionIds } = this;

      if (this.type === 'projects') return this.getProjectsByIds(collectionIds);
      if (this.type === 'services') return this.getServicesByIds(collectionIds);

      return [];
    },

    markers() {
      return this.collection
        .filter(doc => doc.location && isValidPosition(doc.location.position))
        .map(this.buildMarker);
    },
    zoomCorrection() {
      if (this.type === 'projects') return 0;
      if (this.type === 'services') return 2;

      return 0;
    },
    overlays() {
      if (!this.project || !this.project.manifest) return;

      const { blueprint } = this.project.manifest;

      return blueprint ? [blueprint] : undefined;
    },
    controls() {
      if (!this.project || this.project.forecast.length === 0) return [];
      if (this.hidden.controls.forecast) return [];

      return [
        {
          id: 'project-forecast',
          builder: parent => {
            const Component = Vue.extend(ProjectForecast);

            return new Component({
              propsData: { project: this.project },
              i18n,
            }).$mount(parent).$el;
          },
          position: 'RIGHT_TOP',
          classes: ['pt-3', 'pr-3'],
        },
      ];
    },

    hidden() {
      const hidden = {
        map: { mobile: false, desktop: false },
        collection: { mobile: false, desktop: false },
        controls: { forecast: false },
      };

      const routeWithHidden = findRouteWithMeta(this.$route.matched, 'hidden');
      if (routeWithHidden) return _.merge(hidden, routeWithHidden.meta.hidden);

      return hidden;
    },

    currentDocId() {
      const { projectId, serviceId } = this.$route.params;

      if (this.type === 'projects') return projectId;
      if (this.type === 'services') return serviceId;

      return undefined;
    },

    collectionCarouselReady() {
      return this.type === 'projects' || this.type === 'services';
    },
    collectionPreviewReady() {
      return this.type === 'projects' || this.type === 'services';
    },

    display() {
      const { hidden, markers } = this;

      return {
        map: {
          mobile: !hidden.map.mobile,
          desktop: !hidden.map.desktop && markers && markers.length > 0,
        },
      };
    },

    style() {
      const { matched } = this.$route;
      const routeWithFlex = findRouteWithMeta(matched, 'flex');
      let flex = 0;
      if (routeWithFlex) flex = routeWithFlex.meta.flex;

      return { '--flex': flex };
    },
    className() {
      const className = 'v-collection-index';

      const { matched } = this.$route;
      const routeWithAlignment = findRouteWithMeta(matched, 'alignment');
      let alignment = 'right';
      if (routeWithAlignment) alignment = routeWithAlignment.meta.alignment;

      return [className, `${className}--${alignment}`];
    },
  },
  methods: {
    buildMarker(doc) {
      const buildClickListener = doc => {
        let route;

        if (this.type === 'projects')
          route = {
            name: 'projects-show',
            params: { projectId: doc.id },
          };
        else if (this.type === 'services')
          route = {
            name: 'projects-show-services-show',
            params: { serviceId: doc.id },
          };

        return {
          event: 'click',
          handler: () => this.$router.push(route),
        };
      };

      return Object.assign(
        {
          id: doc.id,
          status: doc.status,

          position: doc.location.position,

          listeners: [buildClickListener(doc)],
        },
        this.type === 'projects' && {
          type: 'project',
        },
        this.type === 'services' && {
          orientation: doc.location.angle,
          type: 'service',
        }
      );
    },

    onFocusChange(doc) {
      this.focusedDocId = doc.id;
    },
    updateMobileOffset(height) {
      const HEADER_HEIGHT = 72; // FIX

      this.mobileOffset.top = HEADER_HEIGHT;
      this.mobileOffset.bottom = height;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
+media-down(md)
  .v-collection-index__map-wrapper
    +p-absolute($z-index-base, 0)

  .v-collection-index__carousel-wrapper
    +p-absolute(#{$z-index-base + 1})
    +shell-bottom(0)

    .collection-carousel
      padding: $spacing-2 $spacing-2 $google-logo-height

  .v-collection-index__router-view-wrapper
    +p-relative(#{$z-index-base + 1})

+media-up(md)
  .v-collection-index
    +d-flex-r

  .v-collection-index__preview-wrapper
    z-index: #{$z-index-base + 1}

    display: inline-block

    &, & > .collection-preview
      height: 100%

      box-shadow: $elevation-10

  // (the map and router-view wrappers container)
  .v-collection-index > .d-flex-r
    z-index: $z-index-base

    flex: 1

  .v-collection-index__map-wrapper
    z-index: $z-index-base

    flex: 1

  .v-collection-index__router-view-wrapper
    z-index: #{$z-index-base + 1}

    flex: var(--flex)

    box-shadow: $elevation-10

    & > *
      height: 100%

      overflow-y: scroll

  // - left
  .v-collection-index--left
    // (the map and router-view wrappers container)
    & > .d-flex-r
      flex-direction: row-reverse
</style>
