<template>
  <div class="map-controls v-spacing-3" :style="style">
    <EButton v-bind="buttonBind" @click="onCenterClick">gps-fixed</EButton>
    <SelectableButton
      v-bind="buttonBind"
      :value="mapType === 'satellite'"
      @click="toggleMapType"
    >
      map-satellite
    </SelectableButton>

    <div class="map-controls__zoom">
      <EButton v-bind="buttonBind" @click="zoomIn">add</EButton>
      <EButton v-bind="buttonBind" @click="zoomOut">remove</EButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

// components
import SelectableButton from '@/components/ds/SelectableButton.vue';

export default {
  name: 'MapControls',
  components: { SelectableButton },
  props: {
    center: { type: Object, default: () => ({ x: 0, y: 0 }) },
    offset: {
      type: Object,
      default: () => ({ top: 0, right: 0, bottom: 0, left: 0 }),
    },
  },
  computed: {
    ...mapState('map', ['mapType']),

    buttonBind() {
      return { floating: true, navigation: true, md: true };
    },

    style() {
      return { '--padding-bottom': `${this.offset.bottom}px` };
    },
  },
  methods: {
    ...mapActions('map', [
      'panToWithOffset',
      'toggleMapType',
      'zoomIn',
      'zoomOut',
    ]),

    onCenterClick() {
      this.panToWithOffset({ center: this.center, offset: this.offset });
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$box-shadow: $elevation-5

.map-controls
  padding-bottom: var(--padding-bottom)

  & > .e-button
    box-shadow: $box-shadow

+media-down(md)
  .map-controls__zoom
    display: none

+media-up(md)
  .map-controls__zoom
    & > .e-button
      box-shadow: $box-shadow

    & > .e-button:first-child
      border-bottom-right-radius: 0 !important
      border-bottom-left-radius: 0 !important

    & > .e-button:last-child
      border-top-right-radius: 0 !important
      border-top-left-radius: 0 !important
</style>
