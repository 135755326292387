<template>
  <div class="project-forecast h-spacing-1">
    <div class="d-flex-c d-flex-a-c">
      <div class="d-flex-r d-flex-a-c h-spacing-1">
        <icon weather>{{ dayIcon(today) }}</icon>

        <t semibold>{{ today.temperature.current | asDegrees }}</t>
      </div>

      <t color="gray-2" xs medium center capitalize class="mt-1">
        {{ locationName }}
      </t>
    </div>

    <div
      v-for="(day, index) in forecast"
      :key="index"
      :class="[
        'project-forecast__day',
        { 'project-forecast__day--active': index === 0 },
      ]"
    >
      <div class="d-flex-r d-flex-a-c h-spacing-1">
        <icon weather>{{ dayIcon(day) }}</icon>

        <t regular xs>{{ day.temperature.max | asDegrees }}</t>
        <t regular xs>{{ day.temperature.min | asDegrees }}</t>
      </div>

      <t xs medium center capitalize class="mt-1">
        {{ day.timestamp | asWeatherDate }}
      </t>
    </div>
  </div>
</template>

<script>
import { getWeatherIcon } from './helpers';

export default {
  name: 'ProjectForecast',
  filters: {
    asDegrees(number) {
      return Math.round(number) + '\u00B0';
    },
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    today() {
      const [today] = this.project.forecast;

      return today;
    },
    locationName() {
      let { name } = this.project.location;
      name = _.split(name, ',');

      return name[0];
    },

    forecast() {
      const { forecast } = this.project;

      return _.take(forecast, 4);
    },
  },
  methods: {
    dayIcon(day) {
      return getWeatherIcon(day.icon);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$border-radius: 8px
$padding: 4px

.project-forecast
  padding: $padding

  border-radius: $border-radius
  box-shadow: $elevation-1

  +d-flex-r(center)

.project-forecast__day
  display: none
  flex-direction: column

  padding: $spacing-1

  border-radius: 4px

  cursor: default

  &:nth-child(1)
    display: flex

// responsiveness
@media (min-width: 960px)
  .project-forecast__day:nth-child(2),
  .project-forecast__day:nth-child(3),
  .project-forecast__day:nth-child(4)
    display: flex

@media (min-width: 1125px)
  .project-forecast__day:nth-child(5)
    display: flex

// color
.project-forecast
  background-color: var(--c-background)

.project-forecast__day
  +on-active
    --hover-color: var(--c-secondary)

    background-color: var(--c-gray-0)

    & > div:first-child
      & > .e-icon,
      & > .e-text
        --color: var(--hover-color)

    & > .e-text:last-child
      --color: var(--hover-color)

.project-forecast__day:not(:first-child)
  .e-icon,
  .e-text
    --color: var(--c-gray-2)
</style>
