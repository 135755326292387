<template>
  <div v-if="!global" class="map" />
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import { Activable } from '@/mixins';
import { buildAveragePosition } from './helpers';

// components
import MapControls from '@/components/the-big-ones/Map/Controls.vue';

export default {
  name: 'Map',
  mixins: [Activable],
  props: {
    markers: { type: Array, default: () => [] },
    currentMarkerId: { type: String, default: '' },
    offset: {
      type: Object,
      default: () => ({ top: 0, right: 0, bottom: 0, left: 0 }),
    },
    zoomCorrection: { type: Number, default: 0 },

    overlays: { type: Array, default: () => [] },
    controls: { type: Array, default: () => [] },

    global: { type: Boolean, default: false },
  },
  data() {
    return {
      mapControlsProps: Vue.observable({ center: null, offset: null }),

      controls_: [
        {
          id: 'map-controls',
          builder: parent => {
            const Component = Vue.extend(MapControls);

            const component = new Component({ store: this.$store });
            component._props = this.mapControlsProps;

            return component.$mount(parent).$el;
          },
          position: 'RIGHT_BOTTOM',
          classes: ['pr-3', 'pb-3'],
        },
      ],
    };
  },
  computed: {
    currentMarker() {
      return _.find(this.markers, { id: this.currentMarkerId });
    },
    center() {
      return _.isUndefined(this.currentMarker)
        ? buildAveragePosition(_.map(this.markers, 'position'))
        : JSON.parse(JSON.stringify(this.currentMarker.position));
    },
  },
  watch: {
    markers(current, previous) {
      if (_.isEqual(_.map(current, 'id'), _.map(previous, 'id'))) return;

      this.reset();
    },
    currentMarkerId(current, previous) {
      if (current === previous) return;

      this.updateVisionOpacity();
    },
    center(current, previous) {
      if (!_.isEqual(current, previous))
        this.panToWithOffset({ center: this.center, offset: this.offset });

      this.mapControlsProps.center = this.center;
    },
    offset() {
      this.mapControlsProps.offset = this.offset;
    },

    controls: {
      handler(current, previous) {
        if (current && current.length > 0) {
          const addedControls = current.filter(
            control => !_.find(previous, { id: control.id })
          );

          this.mountControls({ controls: addedControls });
        }

        if (previous && previous.length > 0) {
          const removedControls = previous.filter(
            control => !_.find(current, { id: control.id })
          );

          this.unmountControls({ controls: removedControls });
        }
      },
      immediate: true,
    },
  },
  created() {
    this.mapControlsProps.center = this.center;
    this.mapControlsProps.offset = this.offset;
  },
  async mounted() {
    await this.setup();
  },
  beforeDestroy() {
    this.teardown();
  },
  activated() {
    this.showMap_();
  },
  deactivated() {
    this.hideMap_();
  },
  methods: {
    ...mapActions('map', [
      'getInstance',
      'addMarkers',
      'addOverlays',
      'removeAllMarkers',
      'removeAllOverlays',
      'fitZoomToMarkers',
      'panToWithOffset',
      'zoomOut',
      'mountControls',
      'unmountControls',
    ]),
    ...mapActions('ui', ['showMap', 'hideMap']),

    getParentEl() {
      return this.global ? document.querySelector('#map') : this.$el;
    },

    updateVisionOpacity() {
      const parentEl = this.getParentEl();
      const all = parentEl.querySelectorAll('.vision-overlay');

      if (this.currentMarkerId) {
        const current = parentEl.querySelector(
          `#${this.currentMarkerId}.vision-overlay`
        );
        if (!current) return;

        all.forEach(({ style }) => (style.opacity = 0.25));
        current.style.opacity = 1;
      } else all.forEach(({ style }) => (style.opacity = 1));
    },

    async reset(zoom = true) {
      this.removeAllOverlays();
      this.addOverlays({ overlays: this.overlays });

      this.removeAllMarkers();
      await this.addMarkers({ markers: this.markers });

      this.updateVisionOpacity();

      if (!zoom) return;

      await this.fitZoomToMarkers();
      await this.fitZoomToMarkers({ offset: this.offset });

      if (this.zoomCorrection) this.zoomOut({ zoom: this.zoomCorrection });
    },

    async setup() {
      const parentEl = this.getParentEl();
      await this.getInstance({ parentEl });

      // mounting all controls
      this.mountControls({ controls: [...this.controls_, ...this.controls] });

      this.reset();

      if (this.isActive) this.showMap_();
    },
    teardown() {
      // unmounting all controls
      this.unmountControls({ controls: [...this.controls_, ...this.controls] });

      this.hideMap_();
    },

    showMap_() {
      if (!this.global) return;

      this.showMap();
    },
    hideMap_() {
      if (!this.global) return;

      this.hideMap();
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

#map, .map
  position: relative

  overflow: hidden

  +size(100%)
</style>
